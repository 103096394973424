import React, { Component } from 'react'
import axios from 'axios'
import mainImage from '../src/assets/350.jpg'
// import convergeLogo from '../src/assets/converge.png'
// import creditCards from '../src/assets/credit_cards.png'
import jeffrey from '../src/assets/jeffrey.jpeg'
import './sfdpi.scss'

export default class Sfdpi extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
    this.content = {}
    this.tempContent = {
      address: [
        'CA License #38-002-01-100',
        '350 Townsend Street Suite 205',
        'San Francisco, CA 94107',
        '(415) 905-5555',
      ],
      calltoaction: [
        'Call (415) 905-5555 to schedule an in-person intake appointment',
      ],
      calltoactionspanish: [
        'Llame para una cita de inscripcion',
        'Hablamos en Español',
      ],
      details: [
        '3-Month participants attend 10 three hour classes, one night per week (enrolled for 12 weeks). Classes consist of 1½ hours of Education and 1½ hours of Group Counseling.',
        'Education topics include Legal Rights and Driver License Privileges, Alcohol and Society, Theories of Addiction, Methods of Preventing another DUI and more. Group topics include DUI and Society, Drinking Lifestyle, Alcohol and Driving, Prevention and more.',
        'The County of San Francisco sets the fees approved by the State of CA Department of Health Care Services. Clients are able to enroll by calling our office to set up an appointment (Please no appointment requests by email).  An Intake takes 1 hour.  For the 3-Month Program a down payment of $107 is required at intake appointment, then 3-monthly payments of $202.',
      ],
      directions: [
        'Located between 4th and 5th Steet, on Townsend',
        'Across from the Caltrain Station',
      ],
      email: ['sfdpi@yahoo.com'],
      pricing: [
        '3-Month Program $713',
        '6-Month Program $995',
        '9-Month Program $1,250',
        '12-Hour (Wet Reckless) $317',
        'We offer live virtual services on Zoom',
        'Ofrecemos servicios virtuales en vivo por Zoom',
      ],
      pricingtitle: ['We offer First Offender DUI programs for:'],
      role: ['Program Director'],
      scheduletimes: [
        'Mon 2PM to 5:10PM',
        'Tues, Wed, Fri & Sat 10:00AM to 1:10PM',
      ],
      scheduletimesevenings: ['Mon, Tues, Wed, Thur 6PM to 9:10PM'],
      scheduletitle: ['Live Virtual Services on Zoom offered: ', 'Daytime:'],
      scheduletitleevenings: ['Evenings:'],
      scheduletitlespanish: ['Clases de Español:'],
      scheduletimesspanish: ['Martes y Jueves 6:30 PM to 9:40 PM'],
      signature: ['Jeffrey Nilsen'],
      title: ['Driver Performance Institutes', 'California State'],
      valediction: ['Sincerely,'],
      hoo: [
        'Hours of Operation',
        'Monday-Thursday 9:00AM-3:30PM (Office Open)',
        'Friday & Saturday 9:00AM-3:00PM (Available by Phone)',
      ],
      director: ['Jeffrey Nilsen', 'Program Director, DPI'],
    }
  }

  componentDidMount() {
    // this.getData().then((content) => {
    // this.content = content
    this.setState({ loaded: true })
    // })
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.loaded) {
  //     const convergeScript = document.createElement('script')
  //     convergeScript.src =
  //       'https://www.convergepay.com/hosted-payments/buy_button_script/624744696f554556536747394b666b72532b2f58654141414159356364486c2b'

  //     const buttonEl = document.querySelector('.converge_form')
  //     buttonEl.appendChild(convergeScript)
  //   }
  // }

  // fetch data form the Google Sheet
  async getData() {
    const res = await axios.get('https://sfdpi-express.herokuapp.com/')
    return res.data
  }

  render() {
    const {
      address,
      calltoaction,
      calltoactionspanish,
      // details,
      directions,
      email,
      pricing,
      pricingtitle,
      // role,
      scheduletimes,
      scheduletimesevenings,
      scheduletimesspanish,
      scheduletitle,
      scheduletitleevenings,
      scheduletitlespanish,
      // signature,
      title,
      // valediction,
      // Use temp content incase of server issues
      // } = this.state.loaded ? this.content : this.tempContent
      hoo,
      director,
    } = this.tempContent

    return (
      <div>
        <div>
          <div className='header_wrap'>
            <div className='header_image_container'>
              <img
                src={mainImage}
                className='header_image'
                alt='DPI entrance'
              />
            </div>
            <div className='header_text'>
              {title[0] && <div className='header_title'>{title[0]}</div>}

              {address && (
                <div className='header_address_wrap'>
                  {address.map((a) => (
                    <div className='address' key={a}>
                      {a}
                    </div>
                  ))}
                  {email && (
                    <a href={`mailto:${email[0]}`} className='header_email'>
                      {email[0]}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className='body_text'>
            <div className='callToAction'>
              {calltoaction && (
                <h1 className='callToAction_text'>{calltoaction}</h1>
              )}
              {calltoactionspanish && (
                <>
                  <h2 className='callToAction_text__spanish'>
                    {calltoactionspanish[0]}
                  </h2>
                  <h2 className='callToAction_text__spanish'>
                    {calltoactionspanish[1]}
                  </h2>
                </>
              )}
            </div>

            <div className='pricing_text_wrap'>
              {pricingtitle &&
                pricingtitle.map((a) => (
                  <div className='pricing_title' key={a}>
                    {a}
                  </div>
                ))}

              {pricing && (
                <div className='pricing_wrap'>
                  {pricing.map((a) => (
                    <div className='pricing' key={a}>
                      {a}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Converge Pay button */}
            {/* <div className='converge_wrap'>
              <form className='converge_form'>
                <button
                  className='converge_button g-recaptcha'
                  data-sitekey='6LeubZ0pAAAAAC0vl0UZHsZ1xfYbIM_lkSek787l'
                  data-callback='onSubmit'
                  data-action='submit'
                >
                  Enrolled clients make payment here
                </button>
                <img
                  src={creditCards}
                  className='converge_cc'
                  border='0'
                  alt='Visa, Master Card, American Express, and Discover credit card logos'
                />
                <div className='converge_pB'>
                  <span className='converge_buttonText'>Powered by </span>
                  <img
                    src={convergeLogo}
                    alt='Converge logo'
                    className='converge_logo'
                  />
                </div>
              </form>
            </div> */}

            {/* {details && (
              <div className='details_wrap'>
                {details.map((a) => (
                  <div className='details' key={a}>
                    {a}
                  </div>
                ))}
              </div>
            )} */}

            {/* <div className='valediction_wrap'>
              {valediction && (
                <div className='valediction_sincerely'>{valediction[0]}</div>
              )}

              {signature && (
                <div className='valediction_signature'>{signature[0]}</div>
              )}

              {role && <div className='valediction_role'>{role[0]}</div>}
            </div> */}

            {/* Hours of operation */}
            <div className='hoo_wrap'>
              <h2>{hoo[0]}</h2>
              <div>{hoo[1]}</div>
              <div>{hoo[2]}</div>
            </div>

            <div className='schedule_wrap'>
              {scheduletitle &&
                scheduletitle.map((a) => (
                  <div className='schedule_title' key={a}>
                    {a}
                  </div>
                ))}

              {scheduletimes && (
                <div className='schedule_times_wrap'>
                  {scheduletimes.map((a) => (
                    <div className='schedule_times' key={a}>
                      {a}
                    </div>
                  ))}
                </div>
              )}

              {scheduletitleevenings &&
                scheduletitleevenings.map((a) => (
                  <div className='schedule_title_evenings' key={a}>
                    {a}
                  </div>
                ))}

              {scheduletimesevenings && (
                <div className='schedule_times_evenings_wrap'>
                  {scheduletimesevenings.map((a) => (
                    <div className='schedule_times_evenings' key={a}>
                      {a}
                    </div>
                  ))}
                </div>
              )}

              {scheduletitlespanish &&
                scheduletitlespanish.map((a) => (
                  <div className='schedule_title_spanish' key={a}>
                    {a}
                  </div>
                ))}

              {scheduletimesspanish && (
                <div className='schedule_times_spanish_wrap'>
                  {scheduletimesspanish.map((a) => (
                    <div className='schedule_times_spanish' key={a}>
                      {a}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className='director_wrap'>
              <img
                src={jeffrey}
                className='director_img'
                border='0'
                alt='Visa, Master Card, American Express, and Discover credit card logos'
              />
              <div>
                <div className='valediction_signature'>{director[0]}</div>
                <div>{director[1]}</div>
              </div>
            </div>
          </div>

          <div className='footer'>
            {title[0] && (
              <div className='footer_title footer_section'> {title[0]}</div>
            )}

            {address && (
              <div className='footer_address_wrap footer_section'>
                {address.map((a) => (
                  <div className='footer_address' key={a}>
                    {a}
                  </div>
                ))}
                {email && (
                  <a href={`mailto:${email[0]}`} className='footer_email'>
                    {email[0]}
                  </a>
                )}
              </div>
            )}

            {directions && (
              <div className='footer_directions_wrap footer_section'>
                {directions.map((a) => (
                  <div className='footer_directions' key={a}>
                    {a}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
