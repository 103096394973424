import React, { useState, useEffect } from 'react'
import Sfdpi from './Sfdpi'
import './paymentSuccess.scss'

const PaymentSuccess = () => {
  const [showModal, setShowModal] = useState(true)

  useEffect(() => {
    document.body.classList.add('disableScroll')
  }, [])

  const handleClose = () => {
    setShowModal(false)
    document.body.classList.remove('disableScroll')
  }

  return (
    <>
      <div className='paymentSuccessWrap'>
        {showModal && (
          <>
            <div className='darkenBG' onClick={handleClose} />
            <div className='successModal'>
              <button
                className='successModal_button'
                onClick={handleClose}
                aria-label='Close modal'
              >
                ✕
              </button>
              <h1 className='successModal_h1'>
                <span role='img' aria-label='check mark'>
                  ✅
                </span>{' '}
                Payment Successful.
              </h1>
              <h2 className='successModal_h2'>Thank You!</h2>
            </div>
          </>
        )}
      </div>
      <Sfdpi />
    </>
  )
}

export default PaymentSuccess
