import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Sfdpi from './Sfdpi'
import PaymentSuccess from './PaymentSuccess'
import './App.scss'

function App() {
  return (
    <div className='app'>
      <BrowserRouter>
        <Routes>
          <Route exact path='/success' element={<PaymentSuccess />} />
          <Route path='*' element={<Sfdpi />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
